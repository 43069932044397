<template>
  <!-- 帮助与反馈 -->
  <el-dialog :visible.sync="dialogVisibleforhelp" width="675px" center custom-class="dialog-help" title="客服帮助"
    :close-on-click-modal="false">
    <div class="content-wrap">
      <el-row :gutter="38">
        <el-col :span="12">
          <ul class="problem">
            <li v-for="(item, index) in useHelpList" :key="index">
              <el-tooltip class="item" effect="light" placement="right">
                <div slot="content" class="content" style="width: 300px">
                  <h3>解决方案：</h3>
                  <p v-if="item.content1">{{ item.content1 }}</p>
                  <p v-if="item.content2">{{ item.content2 }}</p>
                </div>
                <p>{{ item.title }}</p>
              </el-tooltip>
            </li>
          </ul>
          <a href="javascript:;" @click="goFeedback">意见反馈留言</a>
        </el-col>
        <el-col :span="12">
          <ul class="info">
            <!-- <li>
              <img src="@/assets/images/other/service.png" />售后微信客服：mayitequan2022
            </li> -->
            <!-- <li>
              <img src="@/assets/images/other/phone.png" />售后电话：17570325801
            </li> -->
            <li>
              <img src="@/assets/images/other/phone.png" />客服电话：0731-89565055
            </li>
            <!-- <li>
              <img
                src="@/assets/images/other/service.png"
              />买号玩微信客服：taohaobang000
            </li> -->
            <li>
              <img src="@/assets/images/other/wx.png" width="22" />加V领福利：mayidianjing666
            </li>
          </ul>

          <a class="service" href="javascript:;" @click="onLineService">点击联系在线客服</a>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import cMethods from "@/util/cMethods.js";

export default {
  computed: {
    ...mapGetters(["userInfo", "isLogin"]),
  },
  data() {
    return {
      dialogVisibleforhelp: false,
      useHelpList: [
        {
          title: "游戏登录上后账号异常、没有游戏",
          content1:
            "1、系统检测到登录账号被封禁时会自动帮您更换账号，如果没有请联系在线客服帮您换号",
          content2:
            "2、Steam游戏“库”中没有对应游戏，可先查看是否被隐藏游戏，如果依旧没有请联系在线客服帮您换号",
        },
        {
          title: "网吧没下载此游戏",
          content1: "1、您登录的账号网吧没有该游戏，请联系网管下载游戏",
          content2: "",
        },
        {
          title: "上不去游戏",
          content1:
            "1、您可以根据系统在上号过程中登录失败提示进行操作，如有问题可随时联系在线客服",
          content2:
            "2、如果您已经登录“steam”在开始游戏的情况下出现无法登录游戏的情况，请随时联系在线客服",
        },
        {
          title: "steam里面加不了好友",
          content1:
            "1、如果您在“steam”界面无法添加好友或者点击好友栏是乱码等情况，请使用加速器加速“steam”即可",
          content2: "",
        },
        {
          title: "一键上号后蓝屏(或卡顿异常等)",
          content1:
            "1、如果出现上号后、或游戏中蓝屏、卡顿等异常情况，请马上联系在线客服帮您解决",
          content2: "",
        },
        {
          title: "为什么下单时提示游戏库存不足？",
          content1: "1、建议您免费体验其他游戏，或在高峰期后在体验。",
          content2: "",
        },
      ],
    };
  },
  mounted() {
    eventBus.$on("showHelpDialog", (toggle) => {
      this.dialogVisibleforhelp = toggle;
    });
  },
  methods: {
    // 去反馈
    goFeedback() {
      _czc.push(["_trackEvent", "客服帮助", "点击", "意见反馈留言"]);

      this.dialogVisibleforhelp = false;
      this.$router.push("/feedback");
    },
    // 在线人工客服
    onLineService() {
      _czc.push(["_trackEvent", "客服帮助", "点击", "联系在线客服"]);
      cMethods.OpenIeUrl(
        "http://www.mayidianjing.com/Content/customerService/xiaoneng.html"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-help {
  .content-wrap {
    height: 350px;
    position: relative;

    .problem {
      height: 300px;
      display: flex;
      flex-direction: column;

      li {
        height: 34px;
        line-height: 34px;
        margin-bottom: 16px;
        padding-left: 10px;
        border-left: 7px solid $bt-linear-col;
        // background: #eef1f5;
        font-size: 16px;
        @include font_col6();
        @include tag_bg_col();
      }

      li:hover {
        color: $bt-linear-col;
      }
    }

    .info {
      height: 300px;
      display: flex;
      flex-direction: column;

      li {
        height: 51px;
        line-height: 51px;
        margin-bottom: 14px;
        padding-left: 15px;
        // background: url("./../../assets/images/other/bg-item.png") no-repeat;
        @include font_col6();
        @include tag_bg_col();
        background-size: 100% 100%;
        color: $bt-linear-col;
        font-size: 16px;

        img {
          vertical-align: middle;
          margin-right: 10px;
        }
      }
    }

    a {
      width: 294px;
      height: 45px;
      line-height: 45px;
      display: block;
      text-align: center;
      font-size: 18px;
      color: $white-col;
      border-radius: 6px;
      background: $red-col;
    }

    a:hover {
      background: $bt-linear-col;
    }
  }
}
</style>

<style lang="scss">
.el-tooltip__popper {
  h3 {
    font-size: 16px;
    margin-bottom: 5px;
    color: $red-col;
  }
}
</style>
