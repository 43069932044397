import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/styles/reset.css";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import VueClipboard from "vue-clipboard2";

import VueDraggableResizable from "vue-draggable-resizable";
import "vue-draggable-resizable/dist/VueDraggableResizable.css";
Vue.component("vue-draggable-resizable", VueDraggableResizable);

import allGlobleComponent from '@/views/components/index.js'
import globalPlugin from '@/views/components/globle/global.js'
// 友盟统计集成
import { init } from '@umengfe/apm';
// , captureException 

init({
  pageFilter: {
    mode: 'ignore',
    rules: []
  },
  pid: '668cc633cac2a664de64ff96',
  logLevel: 0
});

// try {
//   throw new Error('开发者手动捕捉到的错误');
// } catch (error) {
//   captureException(error);
// }

// 安装自定义插件
Vue.use(allGlobleComponent)

Vue.use(ElementUI);
Vue.use(VueClipboard);

// 自动以全局存档弹窗
Vue.use(globalPlugin)
Vue.prototype.$store = store;

import "@/assets/styles/index.scss";

Vue.config.productionTip = false;

window.eventBus = new Vue();

// 全局处理未捕获的错误
Vue.config.errorHandler = function (err, vm, info) {
  // 处理错误
  console.error('Vue error:', err, info);
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
