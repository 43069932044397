import { CompleteTask } from "@/api";
import { mapGetters } from "vuex";
import cMethods from "@/util/cMethods.js";

export const exportForCMethods = {
  computed: {
    ...mapGetters(["isLogin", "userInfo"]),
  },
  data() {
    return {};
  },
  mounted() {
    window.TQ = this;
  },
  methods: {
    // 网页加载完成
    WebPageLoadedSucce() {
      console.log("网页加载完成...");
      // 启动加速器
      cMethods.Form_jsControlsNginxAcce(1);
    },
    // 页面跳转
    navigateTo(url) {
      this.$router.push(url);
    },
    // 本地存储
    setSessionStorage(gstr) {
      localStorage.setItem("gamesStr", gstr);
    },
    // 显示游戏悬浮框
    showFixedGameBox() {
      if (sessionStorage.getItem("runningGame")) {
        this.fixedGameBox = true;
        this.$store.commit("setGameState", 1);
        this.gameInfo = JSON.parse(sessionStorage.getItem("runningGame"));
      }
    },
    // 关闭游戏悬浮框
    hideFixedGameBox() {
      this.fixedGameBox = false;
      this.$store.commit("setGameState", 0);
      // sessionStorage.removeItem("runningGame");
      sessionStorage.removeItem("gameState");
      this.gameInfo = null;
    },
    // 友盟统计
    statistics(str, page, num) {
      _czc.push(["_trackEvent", str, page, num]);
    },

    // 游戏进度条弹窗
    progressBarShow(num, text) {
      this.progressBarDialog = true;
      this.progressNum = num;
      this.progressText = text;
      clearInterval(this.showTimer);
      this.showTimer = setInterval(() => {
        this.timeShow = true;
      }, 40000);
    },
    // 关闭进度条弹窗
    progressBarClose() {
      this.progressBarDialog = false;
      this.progressNum = 0;
      this.progressText = "";
      clearInterval(this.showTimer);
      this.showTimer = null;
    },
    resetBuffText(state) {
      this.state = state;
      if (state == -1) {
        this.buffDisabled = false;
      }
    },
    setBackupArchive(bl) {
      this.$store.commit("setBackupArchive", bl);
    },
  },
};
