<template>
  <div class="header-wrapper">
    <div class="info" @mousedown.stop.prevent="mouse_down()" @mouseup.stop.prevent="mouse_move()">
      <div class="drag_wrapper">
        <div class="e-sports-name">
          <img src="@/assets/img/icon.png" alt="" />
          <!-- <span>蚂蚁特权— </span> -->
          <div v-if="netBarId !== 1">
            <div class="edition" v-if="netBarVIP">
              钻石版 <img src="@/assets/img/diamonds.png" alt="" />
            </div>
            <div class="edition" v-else>黄金版</div>
          </div>
        </div>

        <div>
          <el-tooltip class="item" effect="dark" placement="bottom">
            <template slot="content">
              {{ netBarName }}
            </template>
            <h2 class="internet-cafes-name" v-if="netBarName">
              <img src="@/assets/img/netbar.png" alt="" />
              {{ netBarName.substr(0, 16) }}
            </h2>
          </el-tooltip>


          <p v-if="netBarVIP && netBarVIPEndTime" class="end-time">
            到期时间：{{ netBarVIPEndTime.substr(0, 10) }}
          </p>
        </div>
      </div>
    </div>

    <div class="btn-box-wrap">
      <div>
        <a href="javascript:void(history.go(-1))" class="btn-return" @mouseover="iconReturn = false"
          @mouseout="iconReturn = true">
          <img v-if="iconReturn" src="@/assets/img/arrow-1.png" alt />
          <img v-else src="@/assets/img/arrow-1-1.png" alt />
        </a>
        <!-- <a
          href="javascript:void(history.go(1))"
          class="btn-refresh"
          @mouseover="iconRefresh = false"
          @mouseout="iconRefresh = true"
        >
          <img v-if="iconRefresh" src="@/assets/img/arrow-2.png" alt />
          <img v-else src="@/assets/img/arrow-2-1.png" alt />
        </a> -->
        <a href="javascript:;" class="btn-refresh" @mouseover="iconRefresh = false" @mouseout="iconRefresh = true"
          @click.stop="refresh">
          <img v-if="iconRefresh" src="@/assets/img/refresh-1.png" alt />
          <img v-else src="@/assets/img/refresh-1-1.png" alt />
        </a>
      </div>
    </div>

    <div class="menu-btn-wrap">
      <ul class="nav">
        <li v-for="(item, index) in menuList" :key="index">
          <!-- v-show="!netBarVIP || index !== 1" -->
          <a href="javascript:;" class="item-icon" @click="toLink(item.link)" @mouseover="item.selected = true"
            @mouseout="item.selected = false">
            <img v-if="item.link == $route.path || item.selected" :src="item.imgUrl1" alt />
            <img v-else :src="item.imgUrl2" alt />
            <p>{{ item.title }}</p>
          </a>
        </li>
      </ul>
    </div>

    <div class="operation">
      <div class="close">
        <!-- <span class="unsealing" v-if="userInfo && userInfo.State !== 0" @click="unsealing">申请解封</span> -->
        <span class="unsealing" v-if="userInfo && userInfo.State == 1" @click="unsealing">申请解封</span>
        <el-popover placement="bottom" width="100" trigger="hover">
          <ul>
            <li @click="light">白色主题</li>
            <li @click="dark">黑色主题</li>
          </ul>

          <el-button slot="reference" class="change"><img src="@/assets/img/skin.png" alt /> 皮肤切换</el-button>
        </el-popover>

        <el-tooltip class="item" effect="light" placement="bottom">
          <div slot="content" class="code-box" style="text-align: center">
            <img src="@/assets/images/home/code.png" width="160" height="160" alt />
            <!-- <p>扫一扫，关注微信二维码</p> -->
            <p>扫一扫，加微信领福利</p>
          </div>
          <span class="public">加群领福利</span>
        </el-tooltip>

        <span class="service" @click="feedBack">
          <img class="service-icon" src="@/assets/img/service.png" alt />在线客服
          <!-- ：0731-89565055 -->
        </span>
        <a href="javascript:;" class="btn-box" v-if="isLogin">
          <el-popover placement="bottom" width="100" trigger="click">
            <ul>
              <li @click="signOut">退出登录</li>
            </ul>
            <img slot="reference" src="@/assets/img/menu.png" alt />
          </el-popover>
        </a>
        <a href="javascript:;" class="btn-box" @click="minimize">
          <img src="@/assets/img/minimize.png" alt />
        </a>
        <a href="javascript:;" class="btn-box" @click="exit">
          <img src="@/assets/img/close.png" alt />
        </a>
      </div>

      <div class="drag-wrap" @mousedown.stop.prevent="mouse_down()" @mouseup.stop.prevent="mouse_move()">
        <!-- <div class="notice">请勿使用或尝试使用外挂软件，否则将被封禁账号！</div> -->
      </div>
    </div>

    <!-- 禁用黑名单用户提示弹窗 -->
    <BlackListDialog ref="blacklist" :errorMessage="errorMessage" />

    <!-- 解封申请未通过 -->
    <el-dialog title="解封申请未通过" :visible.sync="failedDialogVisible" width="500px" center custom-class="blackListDialog"
      :close-on-click-modal="false" v-if="errorObj">
      <p>申请解封时间：{{ errorObj.CreateTime }}</p>
      <p>处理时间：{{ errorObj.HandleTime }}</p>
      <p>处理意见：{{ errorObj.ReplyContent }}</p>
    </el-dialog>
  </div>
</template>

<script>
import cMethods from "@/util/cMethods.js";
import { mapGetters } from "vuex";
import HelpDialog from "./../components/HelpDialog";
import { getSetSkin } from "@/api";
import BlackListDialog from "./BListDialog.vue";
import { GetProposalList } from "@/api";

export default {
  name: "VHeader",
  components: {
    HelpDialog,
    BlackListDialog,
  },
  computed: {
    ...mapGetters([
      "getTheme",
      "userInfo",
      "isLogin",
      "navActive",
      "netBarName",
      "netBarVIP",
      "netBarId",
      "netBarVIPEndTime",
    ]),
  },
  data() {
    return {
      errorObj: null,
      failedDialogVisible: true,
      errorMessage: "",
      iconReturn: true,
      iconRefresh: true,
      num: null,
      menuList: [
        {
          title: "首页",
          link: "/index",
          imgUrl1: require("./../../assets/img/7-1.png"),
          imgUrl2: require("./../../assets/img/7.png"),
          selected: false,
        },
        {
          title: "赛事活动",
          link: "/eSports",
          imgUrl1: require("./../../assets/img/8-1.png"),
          imgUrl2: require("./../../assets/img/8.png"),
          selected: false,
        },
        {
          title: "免费玩",
          link: "/home",
          imgUrl1: require("./../../assets/img/9-1.png"),
          imgUrl2: require("./../../assets/img/9.png"),
          selected: false,
        },
        {
          title: "加速服务",
          link: "/steam",
          imgUrl1: require("./../../assets/img/20-1.png"),
          imgUrl2: require("./../../assets/img/20.png"),
          selected: false,
        },
        // {
        //   title: "开通会员",
        //   link: "/members",
        //   imgUrl1: require("./../../assets/img/12-1.png"),
        //   imgUrl2: require("./../../assets/img/12.png"),
        //   selected: false,
        // },
        // {
        //   title: "PUBG优选",
        //   link: "/boutique",
        //   imgUrl1: require("./../../assets/img/14-1.png"),
        //   imgUrl2: require("./../../assets/img/14-1.png"),
        //   selected: false,
        // },
        // {
        //   title: "蚂蚁宠粉节",
        //   link: "/blindBox",
        //   imgUrl1: require("./../../assets/img/blindBox.gif"),
        //   imgUrl2: require("./../../assets/img/blindBox.gif"),
        //   selected: false,
        // },
        // {
        //   title: "陪玩",
        //   link: "/playWith",
        //   imgUrl1: require("./../../assets/images/home/icon-5.png"),
        //   imgUrl2: require("./../../assets/images/home/icon-5-1.png"),
        //   selected: false,
        // },
        // {
        //   title: "电竞教育",
        //   link: "/dj",
        //   imgUrl1: require("./../../assets/images/home/icon-7.png"),
        //   imgUrl2: require("./../../assets/images/home/icon-7-1.png"),
        //   selected: false,
        // },
      ],
    };
  },
  mounted() {
    document.documentElement.setAttribute("data-theme", this.getTheme);

    if (this.userInfo && this.userInfo.Skin == 2) {
      this.dark();
    } else {
      this.light();
    }

    if (this.isLogin && this.userInfo && this.userInfo.State == 1) {
      // this.unsealing();
      this._GetProposalList();
    }
  },
  methods: {
    // 申请解封
    unsealing() {
      this.$refs["blacklist"].apply();
    },
    async _GetProposalList() {
      let params = {
        consumerId: this.userInfo.ConsumerId,
      };
      let res = await GetProposalList(params);
      if (res.StatusCode == 200) {
        if (res.Items.length) {
          this.errorObj = res.Items[0];
          console.log(this.errorObj);
        }
        // else {
        //   this.unsealing();
        // }
        this.$store.dispatch("_GetUserInfo");
      }
    },
    // 刷新
    refresh() {
      if (window.TQ) {
        TQ.refresh();
      }
    },
    light() {
      this.$store.commit("setTheme", "light");
      document.documentElement.setAttribute("data-theme", "light");
      if (this.isLogin && this.userInfo) {
        this.setSkin(1);
      }
    },
    dark() {
      this.$store.commit("setTheme", "dark");
      document.documentElement.setAttribute("data-theme", "dark");
      if (this.isLogin && this.userInfo) {
        this.setSkin(2);
      }
    },
    async setSkin(index) {
      let params = {
        consumerId: this.userInfo.ConsumerId,
        value: index,
      };
      let res = await getSetSkin(params);
      if (res.StatusCode == 200) {
        this.$store.dispatch("_GetUserInfo");
      }
    },
    toLink(link) {
      if (link == "/boutique") {
        _czc.push(["_trackEvent", "PUBG优选菜单", "点击", "访问量"]);
      }

      if (link == "/eSports") {
        _czc.push(["_trackEvent", "赛事活动", "按钮", "点击量"]);
      }

      this.$router.push(link);
    },
    // 在线客服
    feedBack() {
      eventBus.$emit("showHelpDialog", true);
    },
    //鼠标拖动
    mouse_down() {
      cMethods.FormCaptionDown();
    },
    //鼠标松开
    mouse_move() {
      cMethods.FormCaptionUp();
    },
    //最小化窗口
    minimize() {
      cMethods.MiNiMize();
    },
    //关闭窗口
    exit() {
      this.$confirm(
        "退出客户端的同时会关闭游戏,您确定要退出吗？",
        "退出提示：",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
          cMethods.FormExit();
        })
        .catch(() => { });
    },
    // 游戏加速
    gameSpeed() {
      this.$confirm("是否启动游戏加速器？", "提示：", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          cMethods.Open_GGBooster();
        })
        .catch(() => { });
    },
    // 退出登录
    signOut() {
      this.$confirm("是否退出登录？", "提示：", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          if (sessionStorage.getItem("runningGame")) {
            sessionStorage.removeItem("runningGame");
            window.TQ.hideFixedGameBox();
          }
          this.$store.commit("CLEAR_USER");

          // iframe页面清除登录信息
          if (document.getElementById("jfsc")) {
            document.getElementById("jfsc").contentWindow.postMessage("close", "*");
          }

          this.$router.push("/home");
          cMethods.UserLoginOut();
        })
        .catch(() => { });
    },
    // 刷新
    // refresh() {
    //   const loading = this.$loading({
    //     lock: true,
    //     text: "刷新中...",
    //     spinner: "el-icon-loading",
    //     customClass: "loading_tour",
    //     background: "rgba(6, 6, 6, 0.7)",
    //   });
    //   let path = this.$route.path;
    //   this.isRouterOrder = false;
    //   this.$nextTick(function () {
    //     this.$router.push({
    //       path: path,
    //     });
    //     this.isRouterOrder = true;
    //   });
    //   if (sessionStorage.getItem("Phone")) {
    //     this.$store.dispatch("_GetUserInfo");
    //   }
    //   setTimeout(() => {
    //     loading.close();
    //   }, 300);
    // },
  },
  watch: {
    $route: {
      handler(to, from) {
        // 更新积分商城已签到、已领取按钮状态
        if (to.path == "/points" && from.path == "/signin") {
          eventBus.$emit("reloadIframe")
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.header-wrapper {
  height: 100%;
  display: flex;

  .info {
    .drag_wrapper {
      height: 84px;
      padding: 0 0 0 20px;
      cursor: move;
      position: relative;
      overflow: hidden;

      .end-time {
        line-height: 20px;
        font-size: 12px;
        @include font_col9();
      }
    }

    .e-sports-name {
      height: 32px;
      display: flex;
      align-items: center;
      font-size: 16px;

      .edition {
        margin-left: 5px;
      }

      span {
        margin: 0 5px;
      }
    }

    .internet-cafes-name {
      width: 160px;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .btn-refresh {
      position: absolute;
      top: 32px;
      right: 20px;
    }

    .btn-return {
      position: absolute;
      top: 32px;
      right: 80px;
    }
  }

  .btn-box-wrap {
    display: flex;
    padding: 0 20px;
    align-items: center;
    // margin-right: 60px;

    a {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      display: inline-block;
      margin: 0 5px;
    }
  }

  .nav {
    height: 84px;
    display: flex;

    li {
      width: 84px;
      height: 84px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      .item-icon {
        @include font_col6();

        img {
          width: 35px;
          height: 28px;
          margin-bottom: 8px;
        }

        &:hover {
          color: #ff3636;
        }
      }
    }
  }

  .operation {
    display: flex;
    flex-direction: column;
    flex: 1;

    .unsealing {
      margin-right: 10px;
      text-decoration: underline;
    }

    .change {
      padding: 0;
      background: transparent;
      border: none;
      @include font_col6;
      margin-right: 10px;
      font-size: 12px;

      img {
        margin-top: -2px;
      }
    }

    .close {
      width: 100%;
      height: 24px;
      text-align: right;
      padding-right: 10px;
      box-sizing: border-box;
      flex: 1;
      @include font_col6();

      .code-box {
        p {
          font-size: 12px;
        }
      }

      .public {
        margin-right: 15px;
        font-size: 12px;
      }

      .service {
        // width: 190px;
        width: 70px;
        height: 20px;
        line-height: 20px;
        display: inline-block;
        text-align: center;
        margin-left: 8px;
        cursor: pointer;
        vertical-align: middle;
        position: relative;

        .service-icon {
          position: absolute;
          top: 0;
          left: -15px;
        }
      }

      .service:hover {
        text-decoration: underline;
        color: $red-col;
      }

      .btn-box {
        width: 24px;
        height: 24px;
        line-height: 24px;
        display: inline-block;
        text-align: center;
        margin-left: 15px;
      }

      a:hover {
        background: rgba(200, 201, 203, 0.6);
      }
    }

    .drag-wrap {
      width: 100%;
      height: 60px;
      cursor: move;
      position: relative;

      .notice {
        line-height: 40px;
        position: absolute;
        right: 0;
        bottom: 0;
        font-size: 20px;
        color: yellow;
      }
    }
  }
}
</style>

<style lang="scss">
.blackListDialog {
  p {
    font-size: 16px;
    line-height: 30px;
  }
}
</style>