import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import {
  GetUserInfo,
  GetFinalVipGameBysource,
  getIsNotVerificationIDCard,
} from "@/api";

export default new Vuex.Store({
  state: {
    theme: "", // 'light'
    // 实名认证开关
    isAuth: false,
    // 用户信息
    userInfo: null,
    // 是否登录
    isLogin: false,
    // 网吧名
    netBarName: "",
    // 网吧ID
    netBarId: "",
    // 网吧会员
    netBarVIP: false,
    // 钻石版到期时间
    netBarVIPEndTime: "",
    // 高端游戏
    highGames: [],
    // 绝地游戏时长
    timer: 0,
    // 游戏是否启动
    gameState: 0,
    // 启动游戏倒计时
    gameCountDown: 30,
    initialTime: 30,
    goTimeInter: null,
    // 备份存档按钮状态
    backupArchive: false
  },
  getters: {
    gameState(state) {
      if (!state.gameState) {
        state.gameState = sessionStorage.getItem("gameState") || 0;
      }
      return state.gameState;
    },
    getTimer(state) {
      if (!state.timer) {
        state.timer = localStorage.getItem("timer") || 0;
      }
      return state.timer;
    },
    getHighGames(state) {
      if (!state.highGames) {
        state.highGames = localStorage.getItem("highGames");
      }
      return state.highGames;
    },
    getTheme(state) {
      if (!state.theme) {
        state.theme = localStorage.getItem("theme");
      }
      return state.theme;
    },
    getIsAuth(state) {
      return state.isAuth;
    },
    userInfo(state) {
      if (!state.userInfo) {
        state.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      }
      return state.userInfo;
    },
    isLogin(state) {
      if (!state.isLogin) {
        state.isLogin = sessionStorage.getItem("isLogin");
      }
      return state.isLogin;
    },
    navActive(state) {
      if (!state.navActive) {
        state.navActive = sessionStorage.getItem("navActive");
      }
      return state.navActive;
    },
    menuActive(state) {
      if (!state.menuActive) {
        state.menuActive = sessionStorage.getItem("menuActive");
      }
      return state.menuActive;
    },
    netBarName(state) {
      return state.netBarName;
    },
    netBarId(state) {
      return state.netBarId;
    },
    netBarVIP(state) {
      return state.netBarVIP;
    },
    netBarVIPEndTime(state) {
      return state.netBarVIPEndTime;
    },
  },
  mutations: {
    // 控制备份存档按钮状态
    setBackupArchive(state, value) {
      state.backupArchive = value
    },
    setGameCountDown(state, gameCountDown) {
      state.gameCountDown = gameCountDown;
    },
    setGameState(state, gameState) {
      state.gameState = gameState;
      sessionStorage.setItem("gameState", gameState);
    },
    setTimer(state, timer) {
      state.timer = timer;
      localStorage.setItem("timer", timer);
    },
    setHighGames(state, highGames) {
      state.highGames = highGames;
      localStorage.setItem("highGames", highGames);
    },
    setTheme(state, theme) {
      state.theme = theme;
      localStorage.setItem("theme", theme);
    },
    setIsAuth(state, isAuth) {
      state.isAuth = isAuth;
    },
    SET_USERINFO(state, userInfo) {
      state.userInfo = userInfo;
      sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
    },
    SET_ISLOGIN(state, isLogin) {
      state.isLogin = isLogin;
      sessionStorage.setItem("isLogin", isLogin);
    },
    SET_NETBAR_NAME: (state, netBarName) => {
      state.netBarName = netBarName;
    },
    SET_NETBAR_ID: (state, netBarId) => {
      state.netBarId = netBarId;
    },
    SET_VIP: (state, netBarVIP) => {
      state.netBarVIP = netBarVIP;
    },
    SET_NETBAR_VIP_ENDTIME: (state, netBarVIPEndTime) => {
      state.netBarVIPEndTime = netBarVIPEndTime;
    },
    // 清除store用户数据
    CLEAR_USER(state) {
      state.userInfo = null;
      state.isLogin = false;
      state.timer = 0;
      localStorage.removeItem("timer");
      // sessionStorage.clear()
      sessionStorage.removeItem("userInfo");
      sessionStorage.removeItem("isLogin");
      sessionStorage.removeItem("Phone");
    },
  },
  actions: {
    //获取用户信息
    async _GetUserInfo({ commit }) {
      let phone = sessionStorage.getItem("Phone");
      if (phone) {
        let params = {
          phone: phone,
        };
        let res = await GetUserInfo(params);
        if (res.StatusCode == "200" && res.Object) {
          let userObjInfo = res.Object;
          sessionStorage.setItem("Phone", res.Object.Phone);
          // 获取最近游戏
          let params = {
            vipId: res.Object.ConsumerId,
          };
          let rs = await GetFinalVipGameBysource(params);
          let data = JSON.parse(rs.Object);
          if (data.Code == 0 && JSON.stringify(data.Data) !== "{}") {
            let gameInfo = data.Data;
            userObjInfo.GameInfo = gameInfo;
          }
          commit("SET_USERINFO", userObjInfo);
          commit("SET_ISLOGIN", true);

          commit("setTheme", userObjInfo.Skin == 1 ? "light" : "dark");
        } else {
          console.log("用户信息不存在");
        }
      }
    },
    // 获取实名认证开关
    async _getIsNotVerificationIDCard({ commit }) {
      let res = await getIsNotVerificationIDCard({});
      if (res.StatusCode == 200) {
        commit("setIsAuth", res.Object == "true" ? true : false);
      }
    },
    // 登录游戏倒计时
    async getGameCountDown({ commit, state }) {
      clearInterval(state.goTimeInter);
      state.goTimeInter = setInterval(async () => {
        if (state.gameCountDown > 1) {
          state.gameCountDown--;
          // commit("setGameCountDown", state.gameCountDown);
          // console.log(state.gameCountDown);
        } else {
          state.gameCountDown = 30;
          clearInterval(state.goTimeInter);
          // commit("setGameCountDown", 30);
        }
      }, 1000);
    },
  },
});
